import React, { useState, useEffect } from "react";
// import { GiCarBattery, GiPathDistance } from "react-icons/gi";
// import { MdOutlineElectricMeter } from "react-icons/md";
// import { BsSpeedometer } from "react-icons/bs";
// import { FaChargingStation, FaTruckLoading } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
// import img1 from "../image/home/m4.png";

const Usps = () => {
  const [uspsData, setuspsData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
        try {
          const now = new Date();
          const seconds = now.getSeconds().toString().padStart(2, '0');
            const response = await fetch(`https://pgsproducts.com/backend/wp-json/custom/v1/home-second-product-metadata?${seconds}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setuspsData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, []); 


  return (
    <>
      <div className="uspSec">
        <div className="container">
          <div className="row">
            <div class="col-lg-12">
              <h3 class="text-center">
                <span class="subTxt">{uspsData.product_small_title}</span>
              </h3>
              <h2 class="secH text-center">{uspsData.product_heading} </h2>
            </div>
          </div>   
          {uspsData.length === 0 ? (
                        <p>Loading..</p>
                    ) : (    
              <div className="row uspLogosec">
                  {uspsData.all_product_details.map((second, index) => (

                      <div 
                      key={index}
                      className="col-lg-2 col-md-4  col-sm-6">
                      <div className="uspLogo">
                        <Tilt
                          glareEnable={true}
                          tiltMaxAngleX={20}
                          tiltMaxAngleY={20}
                        >
                          <div className="uspsBoxIcon">
                            <img src={second.add_icon} alt={second.title} />
                          </div>
                        </Tilt>
                        <h6>{second.title}</h6>
                      </div>
                      </div>
                  ))}              
                </div>
              )}
        </div>
      </div>
    </>
  );
};

export default Usps;
