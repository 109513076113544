import React, { useState, useEffect } from "react";
import "../css/homebanner.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import productImg1 from "../image/home/product-bg.jpg";
import Modal from "https://cdn.skypack.dev/react-modal@3.16.1";
import ProductBrochureForm from "./ProductBrochureForm.jsx";
import ProductEnqueryForm from "./ProductEnqueryForm.jsx";

const Product = () => {
  const [homeProducts, sethomeProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const response = await fetch(
          `https://pgsproducts.com/backend/wp-json/custom/v1/home-product-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setTimeout(() => {
          sethomeProducts(jsonData);
        }, 2000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const probanner = {
    dots: false,
    Array: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // let subtitle;
  const [modalIsOpenTwo, setIsOpenTwo] = React.useState(false);
  function openModal1() {
    setIsOpenTwo(true);
  }
  function closeModal1() {
    setIsOpenTwo(false);
  }

  return (
    <>
      <section
        className="commonSec productSec productDesk "
        id="sec2"
        style={{ backgroundImage: `url(${productImg1})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-center">
                <span className="subTxt">
                  {homeProducts.product_small_title}
                </span>
              </h3>
              <h2 className="secH text-center">
                {homeProducts.product_heading}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs text-center productTab">
                {homeProducts.length === 0 ? (
                  <h2 class="secH text-center">Loading...</h2>
                ) : (
                  <Tabs>
                    {homeProducts.all_product_details.map((product, index) => (
                      <Tab label={product.tab_product_heading}>
                        {console.log(product)}
                        <div>
                          <div className="productItem" id="ptab-1">
                            <div className="smithTxt">
                              <h3>Smith Motors</h3>
                            </div>
                            <div className="productSliderImg">
                              <img src={product.product_image} alt="" />
                            </div>
                            <div className="productContent">
                              <h4>{product.tab_product_heading}</h4>
                              <div className="priceWrap">
                                <span>{product.product_price}</span>
                              </div>
                              <div className="productDesc">
                                <h5>Specifications:</h5>
                                <ul>
                                  {product.add_specification.map(
                                    (specification, index) => (
                                      <>
                                        <li>
                                          <p>
                                            <span>
                                              {
                                                specification.specification_label
                                              }
                                            </span>
                                            :{" "}
                                            {specification.specification_value}
                                          </p>
                                        </li>
                                      </>
                                    )
                                  )}
                                </ul>
                              </div>
                              <div className="productBtn">
                                <a
                                  href="#"
                                  className="btnH"
                                  onClick={openModal}
                                >
                                  Download Brochure
                                </a>
                                <a
                                  href="#"
                                  className="btnH"
                                  onClick={openModal1}
                                >
                                  Enquiry Now
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="form-popup-bg" id="pop1">
          <div className="form-container_popup">
            <button
              onClick={closeModal}
              className="relative right-0 float-right text-red-600 font-bolder text-2xl"
            >
              X
            </button>
            <h2>Download Brochure</h2>
            <ProductBrochureForm />
          </div>
        </div>
      </Modal>
      {
        <Modal
          isOpen={modalIsOpenTwo}
          onRequestClose={closeModal1}
          contentLabel="Example Modal"
        >
          <div className="form-popup-bg" id="pop2">
            <div className="form-container_popup">
              <button
                onClick={closeModal1}
                className="relative right-0 float-right text-red-600 font-bolder text-2xl"
              >
                X
              </button>
              <h2>Quick Connect</h2>
              <ProductEnqueryForm />
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label,
  };
  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };
  render() {
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, (child) => {
          buttons.push(child.props.label);
          if (child.props.label === this.state.activeTab)
            content = child.props.children;
        })}

        <TabButtons
          activeTab={this.state.activeTab}
          buttons={buttons}
          changeTab={this.changeTab}
        />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <div className="tab-buttons">
      {buttons.map((button) => {
        return (
          <button
            className={button === activeTab ? "active" : ""}
            onClick={() => changeTab(button)}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

const Tab = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Product;
