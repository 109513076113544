import React, { useState, useEffect } from "react";
import "../css/homebanner.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import testimonialImg1 from "../image/home/quote.png";
// import testimonialLogo2 from "../image/home/logo-2.jpg";
// import testimonialLogo3 from "../image/home/logo-3.jpg";
// import testimonialLogo4 from "../image/home/logo-4.jpg";
// import testimonialLogo5 from "../image/home/logo-5.jpg";
// import testimonialLogo6 from "../image/home/logo-6.jpg";
// import testimonialLogo7 from "../image/home/logo-7.jpg";
import testimonialLogo8 from "../image/home/testiBg.jpg";
import testimonialLogo9 from "../image/home/user.png";

const Testimonials = () => {
  const [testimonialDetails, settestimonialDetails] = useState([]);
  const [clientsLog, setClientsLog] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const response = await fetch(
          `https://pgsproducts.com/backend/wp-json/custom/v1/home-testimonial-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();

        setTimeout(() => {
          settestimonialDetails(jsonData);
          setClientsLog(jsonData.clientsLog);
        }, 2000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const testimonialsbanner = {
    dots: false,
    Array: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const testimonialsAutoSlider = {
    dots: false,
    infinite: true,
    Array: false,
    slidesToShow: 9,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section
        className="commonSec testimonialSec"
        id="sec4"
        style={{ backgroundImage: `url(${testimonialLogo8})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h3>
                <span className="subTxt">
                  {testimonialDetails.testimonial_small_title}
                </span>
              </h3>
              <h2 className="secH">{testimonialDetails.testimonial_heading}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {testimonialDetails.length === 0 ? (
                <p>Loading...</p> // Or any other loading indicator
              ) : (
                <Slider {...testimonialsbanner}>
                  {testimonialDetails.testimonial_details.map(
                    (testimonial, index) => (
                      <div className="swiper-slide">
                        <div className="testiItem">
                          <div className="testibox">
                            <div className="quoteIcon">
                              <img src={testimonialImg1} alt="" />
                            </div>
                            <div className="testitxt">
                              <p>{testimonial.testimonial_text}</p>
                            </div>
                            <div className="testiBottom">
                              <div className="testiImg">
                                <img src={testimonialLogo9} alt="" />
                              </div>
                              <div className="testiProfile">
                                <h5>{testimonial.client_name}</h5>
                                <p>{testimonial.client_designation}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="commonSec testimonialSec2"   id="client">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="text-center">
              <span className="subTxt">
                {testimonialDetails.client_small_title}
              </span>
            </h3>
            <h2 className="secH text-center">
              {testimonialDetails.client_logo_heading}
            </h2>
          </div>
        </div>
        <div className="logoSec">
          <Slider {...testimonialsAutoSlider}>
            {clientsLog.map((url, index) => (
              <div className="">
                <div className="logoImg">
                  <img src={url} alt="" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
