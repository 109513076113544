import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";

const Whatsapp = () => {

  const [homeFooter, sethomeFooter] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const response = await fetch(
          `https://pgsproducts.com/backend/wp-json/custom/v1/homepage-footer-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setTimeout(() => {
          sethomeFooter(jsonData);
        }, 2000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <div className="whatsapp">
        <a href={`https://wa.me/${homeFooter.whats_app_number}`} target="_blank">
          <FaWhatsapp />
        </a>
      </div>
    </>
  );
};

export default Whatsapp;
