import React, { useState } from 'react';

function ProductBrochureForm() {
    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        company: '',
        productName: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loader
        try {
            const now = new Date();
             const seconds = now.getSeconds().toString().padStart(2, '0');
            const response = await fetch(`https://pgsproducts.com/backend/wp-json/myplugin/v1/product-brosure-submit?${seconds}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                const pdfBlob = await response.blob(); // Get PDF blob
                const blobUrl = URL.createObjectURL(pdfBlob); // Create URL for blob
                const link = document.createElement('a'); // Create a link element
                link.href = blobUrl; // Set href to blob URL
                link.download = 'submission.pdf'; // Set download attribute
                link.click(); // Click the link
                URL.revokeObjectURL(blobUrl); // Revoke the object URL to free up memory
                alert('Form data submitted successfully.');
                setFormData(initialFormData); // Reset form data
            } else {
                alert('Failed to submit form data.');
            }
        }catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again later.');
        } finally {
            setIsLoading(false); // Hide loader
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <div className="frmWrap">
                        <input
                            type="text"
                            name="name"
                            className="inputTxt"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="frmWrap">
                        <input
                            type="email"
                            name="email"
                            className="inputTxt"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="frmWrap">
                        <input
                            type="number"
                            name="phone"
                            className="inputTxt"
                            placeholder="Phone No"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="frmWrap">
                        <input
                            type="text"
                            name="company"
                            className="inputTxt"
                            placeholder="Company Name"
                            value={formData.company}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="frmWrap">
                        <select
                            name="productName"
                            className="inputTxt"
                            value={formData.productName}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Product Name</option>
                            <option value="Platform Trolley">Platform Trolley</option>
                            <option value="Winch Trolley">Winch Trolley</option>
                            <option value="Tripper Trolley">Tripper Trolley</option>
                            <option value="Platform with side barrier">Platform with side barrier</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="frmWrap frmBtn">
                        <input type="submit" className="btnH" value={isLoading ? 'Submitting...' : 'Submit'} disabled={isLoading} />
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ProductBrochureForm;