import React, { useState } from 'react';

function Form() {
    const initialFormData = {
        name: '',
        email: '',
        company: '',
        number:'',
        subject: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loader
        try {
            const now = new Date();
            const seconds = now.getSeconds().toString().padStart(2, '0');
            const response = await fetch(`https://pgsproducts.com/backend/wp-json/myplugin/v1/submit-form?${seconds}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                alert('Form data submitted successfully.');
                setFormData(initialFormData); // Reset form data
            } else {
                alert('Error submitting form. Please try again later.');
            }
        } catch (error) {
            alert('Error submitting form. Please try again later.', error);
        } finally {
            setIsLoading(false); // Hide loader
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <div className="frmWrap">
                        <input
                            type="text"
                            name="name"
                            className="inputTxt"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="frmWrap">
                        <input
                            type="email"
                            name="email"
                            className="inputTxt"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="frmWrap">
                        <input
                            type="company"
                            name="text"
                            className="inputTxt"
                            placeholder="Company Name"
                            value={formData.company}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="frmWrap">
                        <input
                            type="Number"
                            name="number"
                            className="inputTxt"
                            placeholder="Mobile number"
                            value={formData.number}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="frmWrap">
                        <input
                            type="text"
                            name="subject"
                            className="inputTxt"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="frmWrap">
                        <textarea
                            name="message"
                            className="inputTxt textarea"
                            placeholder="Enter Your Message..."
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="frmWrap frmBtn">
                        <input
                            type="submit"
                            className="btnH"
                            value={isLoading ? '' : 'Submit'}
                            disabled={isLoading}
                        />
                        {isLoading && <div className="loader"></div>}
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Form;