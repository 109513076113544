import React, { useState, useEffect } from "react";
import img2 from "../image/home/application2.webp";

const Application = () => {
  const [toggleState, setToggleState] = useState(1);
  const [backgroundImage, setBackgroundImage] = useState(img2);
  const [applicationData, setApplicationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = new Date();
        const seconds = now.getSeconds().toString().padStart(2, "0");
        const response = await fetch(
          `https://pgsproducts.com/backend/wp-json/custom/v1/home-application-metadata?${seconds}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setApplicationData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleTab = (index, image) => {
    setToggleState(index);
    setBackgroundImage(image);
  };

  const getActiveClass = (index, className) =>
    toggleState === index ? className : "";

  const handleHover = (index) => {
    setToggleState(index);
    setBackgroundImage(
      applicationData.all_application_details[index - 1].background_image
    );
  };

  const TabItemComponent = ({
    icon = "",
    title = "",
    onHover = () =>
      console.error("You passed no hover action to the component"),
    isActive = false,
  }) => {
    return (
      <li
        className={isActive ? "tabs active-tabs" : "tabs"}
        onMouseEnter={onHover}
      >
        <i className={icon}></i>
        <p className="tabitem__title">{title}</p>
      </li>
    );
  };

  return (
    <>
      <section  id="applicationSec"
        className="applicationSec applicationDesk"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div class="col-lg-12">
              <h2 class="secH text-center">
                {applicationData.application_heading}
              </h2>
            </div>
          </div>
        </div>
        <div className="applicationTabMain">
          {applicationData.length === 0 ? (
            <p>Loading..</p>
          ) : (
            <>
              <ul className="tab-list">
                {applicationData.all_application_details.map(
                  (application, index) => (
                    <TabItemComponent
                      key={index}
                      title={application.tab_name}
                      onHover={() => handleHover(index + 1)}
                      isActive={toggleState === index + 1}
                    />
                  )
                )}
              </ul>
              <div className="content-container">
                {applicationData.all_application_details.map(
                  (application, index) => (
                    <div
                      key={index}
                      className={`content ${getActiveClass(
                        index + 1,
                        "active-content"
                      )}`}
                    >
                      <div className="applicationCon">
                        <h2 class="secH2">
                          <spam class="secH3 ">
                            {application.application_title}
                          </spam>
                        </h2>
                        <p>{application.application_description}</p>
                        <div className="applicationImg">
                          <img
                            className="applicationImg1"
                            src={application.application_image}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Application;
